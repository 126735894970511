import React from 'react';
import { motion } from 'framer-motion';

const VisionMissionSection = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <section className="mb-32">
      <div className="grid md:grid-cols-2 gap-12 lg:gap-16">
        <motion.div
          className="bg-[#2a3540] p-8 rounded-2xl shadow-lg border-l-4 border-[#E67E22]"
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
        >
          <h2 className="text-3xl font-chillax-bold mb-6 text-[#E67E22]">Our Vision</h2>
          <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed">
          Newton will redefine the boundaries between physical and digital realities, establishing our exoskeleton technology as the global standard for human-machine interaction. We envision Newton leading a paradigm shift in how humans interact with virtual environments, driving breakthroughs in professional training and industrial operations while fundamentally transforming how people learn and work in digital spaces.          </p>
        </motion.div>
        <motion.div
          className="bg-[#2a3540] p-8 rounded-2xl shadow-lg border-l-4 border-[#E67E22]"
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
        >
          <h2 className="text-3xl font-chillax-bold mb-6 text-[#E67E22]">Our Mission</h2>
          <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed">
          Newton's mission is to revolutionize human interaction with virtual environments through pioneering force feedback technology. We empower organizations to achieve unprecedented training efficiency and accelerate innovation while enabling individuals to experience truly immersive virtual reality. Through relentless innovation and strategic partnerships, we are transforming professional training, industrial operations, entertainment and much more with our groundbreaking exoskeleton technology.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default VisionMissionSection;