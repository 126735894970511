import React from 'react';
import { Link } from 'react-router-dom';
import { Cpu, ChevronRight, ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';

const ProductOverview = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.5, ease: "easeOut" } 
    },
  };

  return (
    <section className="mb-24">
      <motion.div 
        className="flex items-center mb-8"
        variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
      >
        <div className="bg-[#E67E22] p-2 rounded-md mr-4">
          <Cpu size={24} className="text-white" />
        </div>
        <h2 className="text-3xl md:text-4xl font-chillax-bold text-white">
          Transforming Virtual Reality through Physical Realism
        </h2>
      </motion.div>
      
      <motion.div 
        className="bg-[#2a3540] p-8 rounded-2xl shadow-lg mb-12"
        variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
      >
        <p className="text-lg text-[#f2f2f2] font-avenir mb-8 leading-relaxed">
          ExoSuit VR revolutionizes how users move and interact within digital environments. 
          Our integrated dual-piston system, which replaces traditional 360° treadmills, enables 
          natural locomotion in VR—users can walk up virtual stairs, hike uneven terrains, or even 
          sit down in a virtual chair, all while maintaining perfect balance. Meanwhile, the full-body 
          exoskeleton provides haptic force feedback and resistance simulation, making virtual objects 
          feel tangible and heavy, and ensuring that users' motions are accurately mirrored in virtual worlds.
        </p>
        
        <h3 className="text-2xl font-chillax-bold mb-6 text-[#E67E22]">Key Features at a Glance:</h3>
        <ul className="space-y-4 text-[#f2f2f2] font-avenir">
          {[
            { title: "Dual-Piston Locomotion System", description: "Accurately simulates different terrains and ground levels for each foot, enabling realistic walking, climbing, and balancing." },
            { title: "Full-Body Exoskeleton", description: "Provides natural movement in VR and force feedback when interacting with objects, simulating weight, resistance, and texture." },
            { title: "Advanced AI Analysis", description: "Delivers real-time performance insights, automated training recommendations, and continuously optimizes force feedback for improved user results." },
            { title: "Complete Patent Coverage", description: "Our unique technology—integrating the exoskeleton with the dual-piston system—is fully patented, ensuring exclusive innovations." },
            { title: "Multi-Platform Compatibility", description: "Seamlessly integrate ExoSuit VR with leading VR headsets and software platforms, expanding the realm of immersive experiences." }
          ].map((innovation, index) => (
            <li key={index} className="flex items-start">
              <ArrowRight className="text-[#E67E22] mr-3 mt-1 flex-shrink-0" size={20} />
              <div>
                <span className="font-semibold">{innovation.title}:</span> {innovation.description}
              </div>
            </li>
          ))}
        </ul>
      </motion.div>
      
      <motion.div 
        className="bg-[#2a3540] p-8 rounded-2xl shadow-lg mb-12"
        variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
      >
        <h3 className="text-2xl font-chillax-bold mb-6 text-[#E67E22]">ExoSuit VR Applications</h3>
        <ul className="space-y-6">
          {[
            { 
              title: 'Advanced Training Simulations', 
              description: 'Enhance military, security, and emergency response training by simulating realistic terrains, heavy equipment handling, and force feedback in various operational scenarios.' 
            },
            { 
              title: 'Industrial Efficiency and Prototyping', 
              description: 'Enable engineers and designers to physically interact with virtual prototypes and machinery, experiencing real-time force feedback and optimizing designs before production.' 
            },
            { 
              title: 'Immersive Gaming and Entertainment', 
              description: 'From navigating complex virtual terrains to engaging in physically intensive scenarios, gamers experience unprecedented immersion as ExoSuit VR simulates realistic forces and resistances.' 
            }
          ].map((item, index) => (
            <li key={index} className="flex items-start">
              <ArrowRight className="text-[#E67E22] mr-3 mt-1 flex-shrink-0" size={20} />
              <div>
                <p className="font-semibold text-white">{item.title}</p>
                <p className="text-sm text-[#f2f2f2] mt-2">{item.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </motion.div>

      <Link to="/product/details">
        <motion.button
          className="bg-[#E67E22] text-white px-8 py-4 rounded-full hover:bg-[#D35400] transition duration-300 flex items-center text-lg font-semibold"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          Explore ExoSuit VR in Detail <ChevronRight className="ml-2" size={20} />
        </motion.button>
      </Link>
    </section>
  );
};

export default ProductOverview;