// /src/pages/vision/index.js
import React, { useEffect } from 'react';
import VisionHeader from './VisionHeader';
import VisionMissionSection from './VisionMissionSection';
import CoreValuesSection from './CoreValuesSection';
import Separator from './Separator';
import FutureImpactSection from './FutureImpactSection';

/**
 * Vision Page Component
 * 
 * Renders the Vision page with all its sections.
 */
const Vision = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-auto px-6 py-24 max-w-7xl">
      <VisionHeader />
      <VisionMissionSection />
      <CoreValuesSection />
      <Separator />
      <FutureImpactSection />
    </div>
  );
};

export default Vision;
