import React from 'react';
import { motion } from 'framer-motion';

const FutureSection = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: 'easeOut' },
    },
  };

  return (
    <motion.div
      className="mt-24 p-10 bg-gradient-to-r from-[#1d2027] to-[#2a3540] rounded-2xl shadow-xl"
      variants={fadeIn}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: '-100px' }}
    >
      <h3 className="text-3xl font-chillax-bold mb-6 text-white">
        The Future of Physical Interaction in Virtual Worlds
      </h3>
      <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed">
        As we continue to refine our force feedback technology, we're opening up new possibilities
        for how humans interact with virtual environments. The ExoSuit VR is not just about seeing
        and hearing virtual worlds—it's about feeling and physically engaging with them in ways
        that were previously unimaginable.
      </p>
      <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed mt-4">
        From enhancing training and education with tactile feedback to creating more immersive
        entertainment experiences, ExoSuit VR is at the forefront of bridging the gap between
        physical and virtual realities. We're excited to see how this technology will continue to
        transform industries and create new paradigms for human-computer interaction.
      </p>
      <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed mt-4">
      The potential applications of our force feedback technology are boundless. As we look to the future, we envision ExoSuit VR playing a crucial role in accelerating product development, where engineers can physically interact with virtual prototypes before manufacturing, in advanced military training where operators can master complex equipment operation without risk, and in industrial design where teams can collaborate on and physically manipulate virtual models in real-time. This technology will continue pushing the boundaries of what's possible in human-machine interaction.
      </p>
    </motion.div>
  );
};

export default FutureSection;