import React from 'react';
import { Zap, Hand, Brain, Shield } from 'lucide-react';
import { motion } from 'framer-motion';

const InnovationsSection = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" }
    },
  };

  const innovations = [
    {
      title: 'Patented Dual-Piston System',
      icon: Zap,
      description: "Newton's dual-piston locomotion system independently adjusts ground levels for each foot, enabling natural movement through stairs, slopes, and varied virtual terrains."
    },
    {
      title: "Full-Body Haptic Feedback",
      icon: Hand,
      description: "Experience complete immersion as our exoskeleton provides haptic feedback across your entire body, making virtual interactions feel incredibly authentic."
    },
    {
      title: "Adaptive AI Systems",
      icon: Brain,
      description: "Our advanced AI continuously learns and adapts to your movements, refining the physical feedback to match your actions and preferences for a tailored VR experience."
    },
    {
      title: "Comfort and Ergonomics",
      icon: Shield,
      description: "Newton's exoskeleton is designed for long-term wear, ensuring users can engage in extended VR sessions comfortably and naturally."
    }
  ];

  return (
    <section className="bg-gradient-to-r from-[#1d2027] to-[#2a3540] py-24">
      <div className="container mx-auto px-4 md:px-6">
        <motion.h2
          className="text-4xl font-chillax-bold text-white mb-12 text-center"
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          Our Innovative Approach
        </motion.h2>
        <div className="grid md:grid-cols-2 gap-12 mb-16">
          {innovations.map((innovation, index) => (
            <motion.div
              key={index}
              className="bg-[#2a3540] p-8 rounded-lg shadow-lg"
              variants={fadeIn}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, margin: "-100px" }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="flex items-center mb-6">
                <innovation.icon size={32} className="text-[#E67E22] mr-4" />
                <h3 className="text-2xl font-chillax-bold text-white">{innovation.title}</h3>
              </div>
              <p className="text-[#f2f2f2] font-avenir leading-relaxed">{innovation.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default InnovationsSection;